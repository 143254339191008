import { objectPropertyOrFallback } from '../../util';
import BaseModel from './BaseModel';


const imgSrcReg = new RegExp('src="(.*?)"', 'g');
const hrefReg = new RegExp('href="(.*?)"', 'g');

function parseOrZero(position) {
	let parsedPosition = parseInt(position, 10);

	if (isNaN(parsedPosition)) {
		parsedPosition = 0;
	}

	return parsedPosition;
}

function getDividedContent(content) {
	let summary = null;
	const separatorPosition = content.indexOf('<p>======</p>');

	if (separatorPosition > -1) {
		summary = replaceImageDomains(content.substring(0, separatorPosition));
		content = content.substring(separatorPosition + 13);
	}

	content = replaceImageDomains(content);

	return [ summary, content ];
}

function replaceImageDomains(html) {
	return html.replace(/\/auslots\.com\/image\//g, '/spa.au-slots.com/image/');
}

class ApiContent extends BaseModel {

	constructor(data, options = { sort: null, contentTransformer: null }) {
		super(data);
		let children = [];

		if (Array.isArray(data.subcontent)) {
			for (const child of data.subcontent) {
				children.push(new ApiContent(child, options));
			}
		}

		const { sort, contentTransformer } = options;

		if (sort) {
			children = children.sort(sort);
		}

		this.children = children;

		let [ summary, content ] = getDividedContent(this.content);

		if (summary !== null) {
			this.summary = summary;
		} else if (this.summary) {
			if (this.summary.indexOf('<') === -1) {
				this.summary = `<p class="__typography__ paragraph">${this.summary}</p>`;
			} else {
				this.summary = replaceImageDomains(this.summary);
			}
		}

		this.content = content;

		if (contentTransformer) {
			this.content = contentTransformer(this.content);
			this.summary = contentTransformer(this.summary);
		}

		if (this.content) {
			this.content = this.content.replace(
				imgSrcReg,
				(attribute, src) => {
					return `src="${src.split('auslots.com').join('au-slots.com')}"`;
				}
			);

			this.content = this.content.replace(
				hrefReg,
				(attribute, href) => {
					return `href="${href.split('auslots.com/au/').join('au-slots.com/').split('auslots.com').join('au-slots.com')}"`;
				}
			);
		}
	
		if (this.summary) {
			this.summary = this.summary.replace(
				imgSrcReg,
				(attribute, src) => `src="${src.split('auslots.com').join('au-slots.com')}"`
			);
		
			this.summary = this.summary.replace(
				hrefReg,
				(attribute, href) => `href="${href.split('auslots.com').join('au-slots.com')}"`
			);
		}
	}

	static getSlug(data) {
		const customUrl = objectPropertyOrFallback(data, 'custom_link');

		if (customUrl) {
			return customUrl;
		}

		return objectPropertyOrFallback(data, 'url_title');
	}

	static getPosition(data) {
		const position = objectPropertyOrFallback(data, 'position', 0);

		return parseOrZero(position);
	}

	static getParentId(data) {
		const parentId = objectPropertyOrFallback(data, 'parent_id', '0');

		if (parentId === '0') {
			return null;
		}

		return parseOrZero(parentId);
	}

	static getId(data) {
		const id = objectPropertyOrFallback(data, 'id', '0');

		return parseOrZero(id);
	}

	static getMeta(data) {
		const { head_title, meta_description } = data;

		return {
			title: head_title || undefined,
			description: meta_description || undefined
		};
	}

	static getImage(data) {
		const { image, image_alt, image_title } = data;

		return {
			src: image || undefined,
			alt: image_alt || undefined,
			title: image_title || undefined
		};
	}

	static propertyMap = {
		title: null,
		time: null,
		summary: null,
		content: 'text',
		slug: ApiContent.getSlug,
		type: null,
		position: ApiContent.getPosition,
		parentId: ApiContent.getParentId,
		id: ApiContent.getId,
		meta: ApiContent.getMeta,
		image: ApiContent.getImage
	};

}

export default ApiContent;