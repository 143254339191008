import React from 'react';
import { SvgIcon } from '@material-ui/core';


export { default as australia } from './australia.svg';
export { default as diamond } from './diamond.svg';
export { default as logo } from './logo.png';
export { default as support } from './support.svg';
export { default as vigilante } from './vigilante.png';
export { default as licence } from './licence.png';

export function UnitedKingdomIcon(props) {
  return (
    <SvgIcon {...props}>
      <style type="text/css">{`
        .st0{fill:#29337A;}
        .st1{fill:#FFFFFF;}
        .st2{fill:#D32030;}
        .st3{fill:#252F6C;}
        .st4{fill:#E7E7E7;}
        .st5{fill:#D71F28;}
        .st6{fill:#E51D35;}
      `}
      </style>
      <g>
        <g>
          <path className="st0" d="M167,414.3c-0.8,1.1-1.4,2.4-1.7,3.7h5.5L167,414.3z"/>
          <path className="st0" d="M173.5,409.5c-1.4,0.4-2.7,1-3.8,1.9l3.8,3.8V409.5z"/>
          <path className="st0" d="M170,430.9c1.1,0.7,2.2,1.3,3.5,1.7v-5.2L170,430.9z"/>
          <path className="st0" d="M165.4,424.6c0.4,1.3,1,2.5,1.8,3.5l3.5-3.5H165.4z"/>
        </g>
        <g>
          <path className="st1" d="M167.2,414c-0.1,0.1-0.1,0.2-0.2,0.3l3.7,3.7h-5.5c-0.1,0.3-0.2,0.7-0.2,1h7.2L167.2,414z"/>
          <path className="st1" d="M169.7,430.7c0.1,0.1,0.2,0.1,0.3,0.2l3.5-3.5v5.2c0.3,0.1,0.7,0.2,1,0.3v-6.9L169.7,430.7z"/>
          <path className="st1" d="M165.1,423.6c0.1,0.3,0.2,0.7,0.3,1h5.3l-3.5,3.5c0.4,0.5,0.8,1,1.3,1.5l6-6H165.1L165.1,423.6z"/>
          <path className="st1" d="M173.5,409.5v5.7l-3.8-3.8c-0.5,0.4-1,0.8-1.5,1.3l6.3,6.3v-9.7C174.2,409.3,173.8,409.4,173.5,409.5z"/>
        </g>
        <g>
          <polygon className="st2" points="173.5,424.6 173.4,424.6 173.5,424.6 "/>
          <polygon className="st2" points="174.5,423.6 174.5,423.6 174.5,423.5 "/>
          <polygon className="st2" points="179.1,419 179.1,419 179.1,419.1 "/>
          <polygon className="st2" points="173.5,418 173.5,418 173.5,418 "/>
          <polygon className="st2" points="174.5,419 174.5,419 174.5,419 "/>
        </g>
        <g>
          <path className="st3" d="M180.1,429.9v2.7c1.3-0.4,2.5-0.9,3.6-1.7l-2.9-2.9C180.5,428.8,180.4,429.3,180.1,429.9z"/>
          <path className="st3" d="M180.7,414.5l3.2-3.2c-1.1-0.8-2.4-1.5-3.8-1.9v2.8C180.5,413,180.5,413.6,180.7,414.5z"/>
          <path className="st3" d="M186.5,428.2c0.8-1.1,1.4-2.3,1.8-3.6h-5.4L186.5,428.2z"/>
          <path className="st3" d="M188.4,418c-0.4-1.4-1-2.7-1.8-3.8l-3.8,3.8H188.4z"/>
        </g>
        <g>
          <path className="st4" d="M181.4,423.8l0.8,0.8l3.9,3.9c0.1-0.1,0.2-0.2,0.3-0.3l-3.6-3.6h5.4c0.1-0.3,0.2-0.7,0.3-1h-7.1
            C181.5,423.7,181.5,423.6,181.4,423.8z"/>
          <path className="st4" d="M180.6,415.1l3.6-3.6c-0.1-0.1-0.2-0.1-0.3-0.2l-3.2,3.2C180.8,414.7,180.6,415,180.6,415.1z"/>
          <path className="st4" d="M180.1,412v-2.5c-0.3-0.1-0.7-0.2-1-0.2v1.2C179.4,410.9,179.8,411.4,180.1,412z"/>
          <path className="st4" d="M181.4,419h7.3c-0.1-0.3-0.1-0.7-0.2-1h-5.6l3.8-3.8c-0.4-0.5-0.8-1-1.2-1.5l-4.3,4.3
            C181.3,417.7,181.3,418.2,181.4,419z"/>
          <path className="st4" d="M180.6,427.8l3.1,3.1c0.5-0.4,1-0.7,1.4-1.2l-4.1-4.1C180.9,426.5,180.7,427.2,180.6,427.8z"/>
          <path className="st4" d="M179.1,431.5v1.4c0.3-0.1,0.7-0.1,1-0.2v-2.7C179.8,430.5,179.4,431,179.1,431.5z"/>
        </g>
        <path className="st5" d="M181.1,417l4.3-4.3c-0.4-0.4-0.8-0.8-1.2-1.1l-3.4,3.4C181,415.6,181,416.3,181.1,417z"/>
        <g>
          <path className="st2" d="M176.2,409.1c0.2,0,0.4,0,0.6,0C176.6,409.1,176.4,409.1,176.2,409.1z"/>
          <path className="st2" d="M176.8,433.1c-0.2,0-0.4,0-0.6,0C176.4,433,176.6,433.1,176.8,433.1z"/>
          <path className="st2" d="M176.8,433.1c0.8,0,1.5-0.1,2.2-0.2v-1.4C178.4,432.1,177.7,433.1,176.8,433.1z"/>
        </g>
        <g>
          <path className="st5" d="M188.7,419h-7.2c0.1,1.5,0.1,3.1,0,4.5h7.2c0.2-0.8,0.3-1.7,0.3-2.5C188.8,420.4,188.8,419.7,188.7,419z"/>
          <path className="st5" d="M181.4,423.8c-0.1,0.7-0.3,1.3-0.4,1.9l4.1,4.1c0.4-0.4,0.8-0.8,1.1-1.2l-3.9-3.9L181.4,423.8z"/>
        </g>
        <path className="st2" d="M179.1,410.6v-1.3c-0.7-0.1-1.5-0.2-2.2-0.2C177.7,409.1,178.4,409.9,179.1,410.6z"/>
        <g>
          <path className="st0" d="M180.1,430.4c0.3-0.7,0.6-1.4,0.8-2.2l-0.8-0.8V430.4z"/>
          <path className="st0" d="M181,414.3c-0.3-1-0.6-1.8-0.9-2.6v3.5L181,414.3z"/>
        </g>
        <g>
          <path className="st1" d="M181.7,424c0-0.1,0-0.3,0-0.4h-0.5L181.7,424z"/>
          <path className="st1" d="M180.1,415.2v-3.5c-0.3-0.6-0.6-1.1-1-1.5v6.6l2.1-2.1c0-0.1-0.1-0.3-0.1-0.4L180.1,415.2z"/>
          <path className="st1" d="M179.1,419h2.7c-0.1-0.8-0.1-1.6-0.3-2.4L179.1,419z"/>
          <path className="st1" d="M179.1,432c0.4-0.4,0.7-0.9,1-1.5v-3.1l0.8,0.8c0.2-0.7,0.4-1.4,0.5-2.1l-2.3-2.3L179.1,432L179.1,432z"/>
        </g>
        <path className="st6" d="M179.1,432v-8.2l2.3,2.3c0.1-0.7,0.2-1.4,0.3-2.1l-0.4-0.4h0.5c0.1-1.5,0.1-3,0-4.5h-2.7l2.4-2.4
          c-0.1-0.7-0.2-1.3-0.4-2l-2.1,2.1v-6.6c-0.6-0.7-1.4-1.1-2.2-1.1c-0.2,0-0.4,0-0.6,0c-0.6,0-1.2,0.1-1.7,0.2v9.7l-6.3-6.3
          c-0.4,0.4-0.7,0.8-1.1,1.3l4,4l1,1H165c-0.1,0.7-0.2,1.3-0.2,2c0,0.9,0.1,1.7,0.3,2.5h9.4l-6,6c0.4,0.4,0.8,0.8,1.3,1.1l4.8-4.8
          v6.9c0.6,0.1,1.1,0.2,1.7,0.2c0.2,0,0.4,0,0.6,0C177.7,433.1,178.4,432.7,179.1,432z"/>
      </g>
    </SvgIcon>
  );
}

export function AustraliaIcon(props) {
  return (
    <SvgIcon {...props}>
      <style type="text/css">{`
        .st0{fill:#0052B4;}
        .st1{fill:#F0F0F0;}
        .st2{fill:#D80027;}
      `}
      </style>
      <g>
        <path className="st0" d="M188.8,421.1c0,6.6-5.4,12-12,12s-12-5.4-12-12c0,0,12-12,12-12C183.5,409,188.8,414.4,188.8,421.1z"/>
        <g>
          <path className="st1" d="M176.8,409C176.8,409,176.8,409,176.8,409L176.8,409L176.8,409z"/>
          <path className="st1" d="M176.8,421.1L176.8,421.1C176.8,421,176.8,421,176.8,421.1C176.8,421,176.8,421,176.8,421.1z"/>
          <path className="st1" d="M176.8,415.3c0-2.1,0-3.5,0-6.3h0c-6.6,0-12,5.4-12,12h6.3v-3.5l3.5,3.5h2.2c0,0,0,0,0,0c0-0.8,0-1.5,0-2.2
            l-3.5-3.5H176.8L176.8,415.3z"/>
        </g>
        <g>
          <path className="st2" d="M170.9,410.6c-1.9,1.1-3.4,2.6-4.5,4.5v5.9h3.1v-7.3v0h7.3c0-1,0-1.9,0-3.1
            C176.8,410.6,170.9,410.6,170.9,410.6z"/>
          <path className="st2" d="M176.8,419.6l-4.3-4.3h-1.5c0,0,0,0,0,0l5.7,5.7h0C176.8,421.1,176.8,420,176.8,419.6z"/>
        </g>
        <g>
          <polygon className="st1" points="172.1,423.1 172.7,424.5 174.2,424.2 173.5,425.5 174.7,426.5 173.3,426.8 173.3,428.4 172.1,427.4
            170.9,428.4 170.9,426.8 169.4,426.5 170.6,425.5 169.9,424.2 171.4,424.5 "/>
          <polygon className="st1" points="182.8,425.7 183.1,426.4 183.9,426.3 183.5,427 184.1,427.4 183.4,427.6 183.4,428.4 182.8,427.9
            182.2,428.4 182.2,427.6 181.5,427.4 182.1,427 181.7,426.3 182.5,426.4 "/>
          <polygon className="st1" points="179.7,418.4 180.1,419.1 180.8,419 180.5,419.6 181.1,420.1 180.3,420.3 180.3,421.1 179.7,420.6
            179.1,421.1 179.1,420.3 178.4,420.1 179,419.6 178.7,419 179.4,419.1 "/>
          <polygon className="st1" points="182.8,414.3 183.1,415 183.9,414.8 183.5,415.5 184.1,415.9 183.4,416.1 183.4,416.9 182.8,416.4
            182.2,416.9 182.2,416.1 181.5,415.9 182.1,415.5 181.7,414.8 182.5,415 "/>
          <polygon className="st1" points="185.5,417.4 185.8,418.1 186.6,417.9 186.2,418.6 186.8,419.1 186.1,419.2 186.1,420 185.5,419.5
            184.9,420 184.9,419.2 184.1,419.1 184.7,418.6 184.4,417.9 185.2,418.1 "/>
          <polygon className="st1" points="183.6,421.1 183.8,421.8 184.7,421.8 184,422.3 184.2,423.1 183.6,422.6 182.9,423.1 183.1,422.3
            182.5,421.8 183.3,421.8 "/>
        </g>
      </g>
    </SvgIcon>
  );
}